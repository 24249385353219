<template>
    <div v-if="isShowComponent!='beeneefy'">
        <section class=" banner mt-4">
            <div class="container">
                <div class="bannerWrapper">
                    <div class="d-block d-lg-flex no-gutters align-items-center h-100">
                        <div class="col-12 col-xl-8 col-lg-8 order-lg-1">

                        </div>
                        <div id="home_banner_box" class="col-xl-5 col-lg-6 order-lg-0 h-100">
                            <div class="spc-banner">
                                <h2 class="h1 mb-20 text-white">All in One Farm Store</h2>
                                <p class="text-white">Your Animal Needs in One Place.</p>

                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="about-home mt-20 ">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <h3>Story of Our Legendary Founder</h3>
                        <p>At Beenee, we are driven to offer premium-quality animal feed that meets  every customer's diverse nutritional and farming needs. We want every  meal to give life and happiness to your furry and feathered friends. Let  Beenee be the reason behind every wagging tail and happy cluck - a  promise of a wholesome and cheerful life for your beloved companions.</p>
                        <!--<a href="/Aboutus" class="btn btn-primary">View More</a>-->
                        <router-link class="btn btn-primary" to="/Aboutus">View More</router-link>
                    </div>
                </div>
            </div>
        </section>


                    <section class="offerSect">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                                    <div class="py-10 px-8 rounded offerCol div-offerSect-blogimage">
                                        <div>
                                            <h3 class="fw-bold mb-1">Apple and <br> Carrot</h3>
                                            <p>
                                                The Best Animal Feed Online!
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                                    <div class="py-10 px-8 rounded offerCol div-offerSect-newsimage">
                                        <div>
                                            <h3 class="fw-bold mb-1">Hay</h3>
                                            <p>The Best Animal Feed Online!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                                    <div class="py-10 px-8 rounded offerCol div-offerSect-Testimonialsimage">
                                        <div>
                                            <h3 class="fw-bold mb-1">Grass Feed</h3>
                                            <p>The Best Animal Feed Online!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                
        <section class="container ">
            <h2 class="heading text-center">Top Selling Products</h2>
            <div id="top_selling_Products" class="row pt-4 mx-n2">

                <div class="col-lg-3 col-md-6 col-sm-6 px-2 mb-4" v-for="(productitem,index) in ProductList">
                    <div class="card product-card">
                        <!--<div class="discount-badge">
                        {{productitem.discount}}%
                    </div>-->
                        <a class="card-img-top d-block overflow-hidden" @click="ProductDetailpage(productitem.id)">
                            <img :src="productitem.imageUrl" height="350" alt="product">
                        </a>
                        <div class="card-body py-2">
                            <h3 class="product-title fs-sm">
                                <a @click="ProductDetailpage(productitem.id)">
                                    {{productitem.productName}}
                                </a>
                            </h3>
                            <div class="product-price" v-if="productitem.price.toFixed(2) == productitem.salePrice.toFixed(2)">
                                ${{productitem.salePrice.toFixed(2)}}
                            </div>
                            <div class="product-price" v-else>
                                <del>${{productitem.price.toFixed(2)}}</del>
                                ${{productitem.salePrice.toFixed(2)}} <span class="discount-text-orange">({{productitem.discount}}% Off)</span>
                            </div>

                        </div>
                        <div class="card-body card-body-hidden d-flex">

                            <button v-if="productitem.availableStock > 0 " class="btn btn-primary btn-sm d-block w-100 mb-2" @click="Path(productitem.id,productitem.isShip,productitem.localDelCharges)">
                                <i class="ci-cart fs-sm me-1"></i>Add to Cart
                            </button>

                            <button v-else class="btn btn-primary btn-sm d-block w-100 mb-2" disabled>
                                <i class="ci-cart fs-sm me-1"></i>Out Of Stock
                            </button>


                            <div class="text-center">
                                <a class="nav-link-style fs-ms" href="#quick-view" data-bs-toggle="modal" type="button" @click="ProductDetailpage(productitem.id)">
                                    <i class="fa fa-eye"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="featured_category" class="container cstm-mrgin">
            <h2 class="heading text-center">Featured Categories</h2>
            <div class="row pt-4 mx-n2" style="justify-content:center;">
                <!-- Product-->
                <div class="col-lg-3 col-md-6 col-sm-6 px-2 mb-4" v-for="(prodcatitem,index) in ProductCatgoryList">
                    <div class="categorybx">
                        <a @click="productdetails(prodcatitem.id)">
                            <div class="catwrap"><img :src="prodcatitem.imageUrl" class="img-fluid" /></div>
                            <h3>{{prodcatitem.categoryName}}</h3>
                        </a>
                    </div>
                </div>

            </div>
        </section>
        <section class="container ">
            <h2 class="heading text-center">Recommended For You</h2>
            <div class="row pt-4 mx-n2">
                <!-- Product-->
                <div class="col-lg-12">
                    <div class="recommendProdct">
                        <div class="card product-card bg-grey" v-for="(prodcatitem,index) in RecommendedProductList">
                            <div class="discount-badge blank"></div>
                            <!--<div class="saving-badge">{{prodcatitem.discount}}%</div>-->
                            <a class="card-img-top d-block overflow-hidden" @click="ProductDetailpage(prodcatitem.id)">
                                <img :src="prodcatitem.imageUrl" height="300" alt="dog-house">
                            </a>
                            <div class="card-body py-2">
                                <h3 class="product-title fs-sm"><a @click="ProductDetailpage(prodcatitem.id)">{{prodcatitem.productName}}</a></h3>

                                <div class="product-price" v-if="prodcatitem.price.toFixed(2) == prodcatitem.salePrice.toFixed(2)">
                                    ${{prodcatitem.salePrice.toFixed(2)}}
                                </div>
                                <div class="product-price" v-else>
                                    <del>${{prodcatitem.price.toFixed(2)}}</del> ${{prodcatitem.salePrice.toFixed(2)}}
                                    <span class="discount-text-orange">({{prodcatitem.discount}}% Off)</span>
                                </div>

                            </div>
                            <div class="card-body card-body-hidden d-flex">
                                <button class="btn btn-primary btn-sm d-block w-100 mb-2" v-if="prodcatitem.availableStock > 0" type="button" @click="Path(prodcatitem.id)">
                                    <i class="ci-cart fs-sm me-1"></i>Add to Cart
                                </button>
                                <button v-else class="btn btn-primary btn-sm d-block w-100 mb-2" disabled>
                                    <i class="ci-cart fs-sm me-1"></i>Out Of Stock
                                </button>

                                <div class="text-center">
                                    <a class="nav-link-style fs-ms" href="#quick-view" data-bs-toggle="modal" type="button" @click="ProductDetailpage(prodcatitem.id)">
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section class="offerSect offersrow">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6 col-md-12 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol div-Animal-image">
                            <div>
                                <div class="cat-badge">Animal Care </div>
                                <h3 class="font-40 text-white fw-bold mt-4 mb-3">Advanced Veterinary <br>Dentistry</h3>
                                <p class="text-white"> Practice Limited to Veterinary Dentistry </p>
                                <!--<a href="#" class="btn btn-white">Shop Now</a>-->
                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol smoffer mb-3 div-Pickup-image">
                            <div>
                                <h3 class="font-30  text-white fw-bold mb-1">Curbside Pickup</h3>
                                <p class="text-white"> Free Pick-up in Store </p>
                                <!--<a href="#" class="btn btn-white">Shop Now</a>-->
                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                        <div class="py-10 px-8 rounded offerCol smoffer div-Delivery-image">
                            <div>
                                <!--<h3 class="font-30  fw-bold text-white mb-1">Local Delivery</h3>-->
                                <h3 class="font-30  fw-bold text-white mb-1">Shipping Nationwide</h3>
                                <p class="text-white"> Same Day Fulfillment </p>
                                <!--<a href="#" class="btn btn-white">Shop Now</a>-->
                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol div-sale-offer-image">
                            <div>
                                <p class="smHeading"> SALE </p>
                                <div class="perc"><b>20%</b> Off on</div>
                                <h3 class="lg-heading fw-bold mb-1">Equine</h3>
                                <p class="prod"> Products </p>
                                <!--<a href="#" class="btn btn-white">Shop Now</a>-->

                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="offerSect ranchmap">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-md-12 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol div-sale-ranchmap-image">
                            <div>
                                <!--<div class="tag-badge">Ranch Map</div>-->
                                <h2 class="sub-heading text-white fw-bold mb-1">
                                    Fruit Picking & Farm Day
                                </h2>
                                <p class="text-white"> More Than 500+ Items </p>
                                <button class="btn btn-white" @click="redirectproductlisting()">Shop Now</button>

                                <!--<a href="#" class="btn btn-white">Shop Now</a>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--<section class="service-area">
        <div class="container">
            <div class="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon"><img alt="Thumbnail" src="../assets/images/service-icon1.png"></div>
                        <div class="content">
                            <h6 class="title">Free Pick-up in Store</h6>
                            <p>1 hour or less*</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon"><img alt="Thumbnail" src="../assets/images/service-icon2.png"></div>
                        <div class="content">
                            <h6 class="title">Same Day Delivery</h6>
                            <p>Buy it today. Get it today with same day delivery.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon"><img alt="Thumbnail" src="../assets/images/service-icon3.png"></div>
                        <div class="content">
                            <h6 class="title">Financing Available</h6>
                            <p>Calculate your payment today.</p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="service-box service-style-2">
                        <div class="icon"><img alt="Thumbnail" src="../assets/images/service-icon4.png"></div>
                        <div class="content">
                            <h6 class="title">Neighbor's Club </h6>
                            <p>Earn points with purchases.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>-->

        <Loading v-model:active="isLoading">
        </Loading>
    </div>

    <div v-if="isShowComponent=='beeneefy'">
        <HomeComponent></HomeComponent>
    </div>
</template>


<script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Form, Field } from 'vee-validate';
    import { Pagination, Navigation, Scrollbar, Autoplay } from 'swiper';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';
    import 'swiper/css/virtual';
    import service from '@/service/FrontendService.js'
    import * as Yup from 'yup';
    import InputMask from "@/views/shared/inputmask/inputmasktemplate.vue"
    import ECommerce from '@/service/ECommerce.js';
    import 'swiper/css';

    export default {
        name: 'HomeView',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'beeneefy') {
                this.$options.components.HomeComponent = require('@/views/beeneefy/HomeView.vue').default

            }


        },

        components: {
            HomeComponent: () => {
                if (this.themeName == 'beeneefy') {
                    import('@/views/beeneefy/HomeView.vue');
                }

            },

            Form, Field, InputMask,
            Swiper,
            SwiperSlide,
            /*Header*/
        },
        props: ['homes', 'searchValue'],
        data() {
            const companyId = localStorage.getItem("SupcompanyId")
            const schema = Yup.object().shape({
                firstname: Yup.string().required('First name is required'),
                lastname: Yup.string().required('Last name is required'),
                phone: Yup.string().required('Phone is required'),
                companyname: Yup.string().required('Company Name is required'),
                subject: Yup.string().required('Subject is required'),
                message: Yup.string().required('Message is required'),
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            const schemaemail = Yup.object().shape({
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            return {
                modules: [Pagination, Navigation, Scrollbar, Autoplay],
                schema,
                schemaemail, isShowComponent: false,
                firstname: '',
                lastname: '',
                contactusData: {
                    id: null,
                    name: '',
                    companyname: '',
                    email: null,
                    phone: '',
                    subject: '',
                    message: '',
                    type: '',
                    companyId: localStorage.getItem("SupcompanyId")
                },

                ProductCatgoryList: null,
                //TopProductCatgoryList: null,
                ProductList: '',
                RecommendedProductList: [],
                SaverTodayProductList: [],
                emailvalue: null,
                isLoading: true,
                isMarketPlace: true,
                IsFeateured: true,
                IsFeateureds: false,
                companyId,

                id: null,
                search: '',
                Headerpage: true,
                searchproduct: '00Test',
                CompanyDynamicContent: [],
                bannervideo: [],
                description: [],
            }
        },
        created() {
            this.isShowComponent = localStorage.getItem('theme');
            if (this.searchValue != null || this.searchValue != undefined) {
                this.searchproduct = this.searchValue;;
                this.$emit("Homesss");
            }
            this.isLoading = true;
            this.GetProductList();
            this.GetProductCatgoryList();
            this.GetSaverTodayProductList();
            this.GetRecommendedProductList();
            this.GetDynamicContent();
            (function () {
                const second = 1000,
                    minute = second * 60,
                    hour = minute * 60,
                    day = hour * 24;

                //I'm adding this section so I don't have to keep updating this pen every year :-)
                //remove this if you don't need it
                let today = new Date(),
                    dd = String(today.getDate()).padStart(2, "0"),
                    mm = String(today.getMonth() + 1).padStart(2, "0"),
                    yyyy = today.getFullYear(),
                    nextYear = yyyy + 1,
                    dayMonth = "09/30/",
                    birthday = dayMonth + yyyy;

                //today = mm + "/" + dd + "/" + yyyy;
                //if (today > birthday) {
                //    birthday = dayMonth + nextYear;
                //}
                //end

                const countDown = new Date(birthday).getTime(),
                    x = setInterval(function () {

                        const now = new Date().getTime(),
                            distance = countDown - now;
                    }, 0)
            }())

        },

        updated() {
            window.scrollTo(0, 0);
        },
        mounted() {
            //
            this.$route.params
        },
        methods: {
            GetDynamicContent() {
                this.isLoading = true;
                ECommerce.GetDynamicContentForComapny(localStorage.getItem("companyId")).then(res => {
                    this.CompanyDynamicContent = res.data;
                    this.bannervideo = this.CompanyDynamicContent.bannerVideoLink.split(',');
                    if (this.CompanyDynamicContent.description != null) {
                        this.description.push(this.CompanyDynamicContent.description.split('</p>,'));
                        this.isLoading = false;
                    }
                    else {
                        this.description = null;
                        this.isLoading = false;
                    }
                    const favicon = document.getElementById("favicon");
                    favicon.href = this.CompanyDynamicContent.favIconLink;
                    this.$parent.$parent.FooterLogoDescription = res.data.footerContent;
                    this.isLoading = false;
                })
            },
            headerfalse() {
                //
                this.hom = false;;
            },
            Search(value) {


                this.search = value
            },
            GetProductList() {
                this.isLoading = true;
                this.IsFeateured = false;
                this.companyId = '00Test'
                this.searchproduct = (this.searchproduct == null) ? '00Test' : this.searchproduct;
                ECommerce.GetProductListingFront_Home(this.isMarketPlace, this.IsFeateureds, this.companyId, this.searchproduct).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        console.log('data', response.data);
                        this.ProductList = null;
                        this.ProductList = response.data
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            GetRecommendedProductList() {
                this.isLoading = true;
                this.IsFeateured = false;
                this.companyId = '00Test'
                ECommerce.GetRecommededProductListingFrontHome(this.isMarketPlace, this.IsFeateured, this.companyId, this.searchValue).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        ///console.log('data', response.data);
                        this.RecommendedProductList = response.data;
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            GetSaverTodayProductList() {
                this.isLoading = true;
                this.IsFeateured = false;
                this.companyId = '00Test'
                ECommerce.GetSaverTodayProductListingHome(this.isMarketPlace, this.IsFeateured, this.companyId).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        //console.log('data', response.data);
                        this.SaverTodayProductList = response.data;
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            GetProductCatgoryList() {
                this.isLoading = true;
                this.companyId = '00Test'
                this.IsFeateureds = true;
                ECommerce.GetProductCategoryListFront(this.isMarketPlace, this.IsFeateureds, this.companyId).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        //console.log('ProductCatgoryListdata', response.data);
                        this.ProductCatgoryList = response.data
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            productdetails(Id) {
                this.$router.push({ path: `/Products/${Id}` })
            },

            redirectproductlisting() {
                //redirects to product page(all categories)
                this.$router.push({ path: `/ProductList` })
                // this.$router.push({ path: `/Products` })
            },
            onSubmit() {
                this.isLoading = true;
                this.contactusData.name = this.firstname + ' ' + this.lastname;
                this.contactusData.type = 'Request Demo';
                service.savecontactus(this.contactusData).then(res => {
                    this.isLoading = false;
                    if (res.data == 1) {
                        this.success('Your request has been submitted successfully. Our team will contact you soon.');
                        this.$router.push({
                            name: "thankyou",
                            state: {
                                routeMessage: {
                                    IsSuccess: true,
                                    displayMessage: 'Your request has been submitted successfully. Our team will contact you soon.'
                                }
                            }
                        });
                    }
                    else {
                        this.error('Something went wrong')
                    }
                }).catch(res => {
                    this.error("Something went wrong. Please contact site administrator.");
                    this.isLoading = false;
                })
            },
            ProductDetailpage(Id) {
                this.$router.push({ path: `/ProductDetail/${Id}` })
            },
            Path(Id, isShip, localDelCharges) {
                this.isLoading = true;
                if (localStorage.getItem('userlogin') == "false") {
                    this.$router.push({ path: `/Login` })
                }

                else {
                    let subscriptionInfo = localStorage.getItem("SubscriptionDetails");
                    var subscriptiondata = JSON.parse(subscriptionInfo);
                    if (subscriptiondata != null) {
                        if (subscriptiondata.subscriptiontype != null || subscriptiondata.subscriptiontype != 'undefined') {
                            this.error("You cannot add more than one product in subscription!");
                            this.isLoading = false;
                            return;
                        }
                    }
                    if (isShip == false) {
                        localStorage.setItem('IsShip', isShip)
                        localStorage.setItem('SelectedProductId', Id);
                        localStorage.setItem('localDelCharges', localDelCharges);
                    }
                    localStorage.setItem('cartaction', 'cartcalc');
                    this.$router.push({ path: `/Cart/${Id}` })
                }
                this.isLoading = false;

            },
        }
    }
</script>

<style scoped>
</style>

