<template>
    <main class="main position-relative" id="mains">

        <section class="hero-1" id="hero">
            <div class=" gt-slider hero-slider1 " id="heroSlide1">

                <swiper :slides-per-view="1"
                        :space-between="50"
                        @swiper="onSwiper"
                        @slideChange="onSlideChange"
                        :autoplay="{ delay: 3000, disableOnInteraction: false }">

                    <swiper-slide>
                        <div class="hero-inner">
                            <div class="gt-hero-bg background-image"
                                 :style="{ backgroundImage: 'url(' + require('@/assets/Theme/CSS/img/banner_2-1.jpg') + ')' }"></div>
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-xl-6">
                                        <div class="hero-style1">
                                            <h1 class="hero-title text-dark">
                                                <span class="title1 text-dark slideindown" data-ani="slideindown" data-ani-delay="0.3s" style="animation-delay: 0.3s;">Turn Your</span>
                                                <span class="title2 text-dark slideindown" data-ani="slideindown" data-ani-delay="0.4s" style="animation-delay: 0.4s;">Local Feed Store  </span>
                                                <span class="title2 text-dark slideindown" data-ani="slideindown" data-ani-delay="0.5s" style="animation-delay: 0.5s;">Into a National Powerhouse</span>
                                            </h1>
                                            <p class="hero-text text-dark slideinup" data-ani="slideinup" data-ani-delay="0.5s" style="animation-delay: 0.5s;">
                                                Effortless <span> Ordering and Delivery</span>
                                            </p>
                                            <a href="#contact-form" class="gt-btn slideinup" data-ani="slideinup" data-ani-delay="0.6s" style="animation-delay: 0.6s;">
                                            </a>
                                            <a href="http://202.164.57.203/DotNet/beenee/beenify/html/index.html" class="text-btn text-grey" data-ani="slideinup"
                                               data-ani-delay="0.7s">Explore Our Services</a>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 mb-60">
                                        <img src="@/assets/Theme/CSS/img/slider-img.png" alt="slider">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="hero-inner">
                            <div class="gt-hero-bg background-image"
                                 :style="{ backgroundImage: 'url(' + require('@/assets/Theme/CSS/img/banner.jpg') + ')' }"></div>
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-xl-6">
                                        <div class="hero-style1">

                                            <h1 class="hero-title text-white">
                                                <span class="title1 slideindown" data-ani="slideindown" data-ani-delay="0.3s" style="animation-delay: 0.3s;">Everything your </span>
                                                <span class="title2 slideindown" data-ani="slideindown" data-ani-delay="0.4s" style="animation-delay: 0.4s;">feed store needs in</span>
                                                <span class="title2 slideindown" data-ani="slideindown" data-ani-delay="0.5s" style="animation-delay: 0.5s;">one place </span>
                                            </h1>
                                            <p class="hero-text text-white slideinup" data-ani="slideinup" data-ani-delay="0.5s" style="animation-delay: 0.5s;">
                                                Your All-in-One Solution Free Website, CRM, and <span> Sales!</span>
                                            </p>
                                            <a href="#contact-form" class="gt-btn slideinup" data-ani="slideinup" data-ani-delay="0.6s" style="animation-delay: 0.6s;">GET A FREE WEBSITE TODAY</a>
                                            <a href="http://202.164.57.203/DotNet/beenee/beenify/html/index.html" class="text-btn" data-ani="slideinup"
                                               data-ani-delay="0.7s">Explore Our Services</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>


                </swiper>


            </div>
            <button data-slider-prev="#heroSlide1" class="slider-arrow style2"><i class="fa-solid fa-arrow-left"></i></button>
            <button data-slider-next="#heroSlide1" class="slider-arrow style2 slider-next"><i class="fa-solid fa-arrow-right"></i></button>
            <div class="scroll-down">
                <a href="" class="text-dark"><i class="fab fa-facebook-f"></i></a>
                <a href="" class="text-dark"><i class="fab fa-twitter"></i></a>
                <a href="" class="text-dark"><i class="fab fa-instagram"></i></a>
            </div>
        </section>


        <div class="counter-area fix">
            <div class="container">
                <div class="counter-wrap">
                    <div class="counter-box style2 wow fadeInUp" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                        <div class="rating-box">
                            <p>Customers Rating</p>
                            <ul>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                            </ul>
                            <p>Excellent</p>
                            <span class="text-white">4.9 Rating 1000+ Reviews</span>
                        </div>
                    </div>
                    <div class="counter-box style2 wow fadeInUp" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;">

                        <div class="counter-box_counter">
                            <div>
                                <img src="@/assets/Theme/CSS/img/freewebsite.png" alt="freewebsite">
                            </div>
                            <span>Get Your Own Website for Free</span>
                        </div>
                    </div>
                    <div class="counter-box style2 wow fadeInUp" data-wow-delay=".9s" style="visibility: visible; animation-delay: 0.9s; animation-name: fadeInUp;">

                        <div class="counter-box_counter ">
                            <div>
                                <img src="@/assets/Theme/CSS/img/subscription-business-model.png" alt="subscription">
                            </div>
                            <span>Setup Monthly Subscriptions</span>
                        </div>
                    </div>
                    <div class="counter-box style2 wow fadeInUp" data-wow-delay="1s" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">

                        <div class="counter-box_counter ">
                            <div>
                                <img src="@/assets/Theme/CSS/img/tracking.png" alt="tracking">
                            </div>
                            <span>Track Orders in Real-time</span>
                        </div>
                    </div>
                    <div class="counter-box style2 wow fadeInUp" data-wow-delay="1s" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">

                        <div class="counter-box_counter ">
                            <div>
                                <img src="@/assets/Theme/CSS/img/taxes.png" alt="taxes">
                            </div>
                            <span>Autotax Calculation</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <section class="about-area space fix position-reltive">
            <div class="about-wrap style1">

                <div class="container-lg">
                    <div class="about-wrapper style1">
                        <div class="about-thumb-box wow fadeInLeft" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInLeft;">
                            <img src="@/assets/Theme/CSS/img/benify_marketplace.png" alt="thumb">
                        </div>
                        <div class="about-content wow fadeInRight" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInRight;">
                            <div class="title-area">
                                <h5 class="subtitle">  Local Roots, Global Reach </h5>
                                <h2 class="title text-start">The Solution: Beenify Marketplace - A Gateway to Millions</h2>
                                <p class="text">Welcome to Beenify, your gateway to empowering local Feed and Pet supply stores to flourish in the dynamic digital marketplace. Through our suite of comprehensive services, we facilitate seamless establishment of a robust online presence, effortless product sales, and efficient shipping management. All this is achieved without compromising the distinct brand identity and unwavering dedication to quality that defines each store.  </p>
                                <a href="#contact-form" class="gt-btn btn-hollow">Get Beenified Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="service-area space mt-1 fix bg-light1">
            <div class="container">
                <div class="title-area mx-auto title-flex">
                    <div>
                        <h5 class="subtitle wow fadeInUp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;"> Why Beenify? </h5>
                        <h2 class="title mb-50 wow fadeInUp" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">Advantages of Beenify <br> Services</h2>
                    </div>
                    <p>Beenify acts as a central hub for <strong>12,000+</strong> Feed stores across the US. Connecting customers with local stores that have the specific products they need</p>
                </div>
                <div class="service-card-wrapper style2">
                    <div class="service-card style2 wow fadeInUp" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                        <div class="service-icon">
                            <img src="@/assets/Theme/CSS/img/cutomer_needs.png" alt="icon">
                        </div>
                        <div class="service-content">
                            <h3 class="service-content_title">
                                Cater to Customer Needs
                            </h3>
                            <p class="service-content_text">Sellers handle fulfillment themselves, offering flexibility in shipping methods and costs.</p>
                            <a class="link" href="http://202.164.57.203/DotNet/beenee/beenify/html/Contact.html">
                                Learn More <i class="fa-sharp fa-light fa-arrow-right-long"></i>
                            </a>
                        </div>
                    </div>
                    <div class="service-card style2 wow fadeInUp" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;">
                        <div class="service-icon">
                            <img src="@/assets/Theme/CSS/img/ordering_dlivery.png" alt="icon">
                        </div>
                        <div class="service-content">
                            <h3 class="service-content_title">
                                Effortless Ordering &amp; Delivery
                            </h3>
                            <p class="service-content_text">Save expenses, let Independent Delivery Drivers accept jobs at lower prices.</p>
                            <a class="link" href="http://202.164.57.203/DotNet/beenee/beenify/html/Contact.html">
                                Learn More <i class="fa-sharp fa-light fa-arrow-right-long"></i>
                            </a>
                        </div>
                    </div>
                    <div class="service-card style2 wow fadeInUp" data-wow-delay=".9s" style="visibility: visible; animation-delay: 0.9s; animation-name: fadeInUp;">
                        <div class="service-icon">
                            <img src="@/assets/Theme/CSS/img/reach_effeciency.png" alt="icon">
                        </div>
                        <div class="service-content">
                            <h3 class="service-content_title">
                                Expanding Reach and Efficiency
                            </h3>
                            <p class="service-content_text">Expanding the delivery reach and lowering fulfillment costs.</p>
                            <a class="link" href="http://202.164.57.203/DotNet/beenee/beenify/html/Contact.html">
                                Learn More <i class="fa-sharp fa-light fa-arrow-right-long"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--style="background-image: url('@/assets/Theme/CSS/img/focus_business.png'); "-->
        <section class="offering-area fix">
            <div class="offering-wrap style1 background-image"
                 :style="{ backgroundImage: 'url(' + require('@/assets/Theme/CSS/img/focus_business.png') + ')' }">

                <div class="container">
                    <div class="title-area text-center mx-auto">
                        <h3 class="title text-white wow fadeInUp" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">Focus on Local Businesses <span>Millions of independent stores in the US lack the resources or expertise to compete online</span></h3>
                        <a href="#contact-form" class="gt-btn btn-yellow wow fadeInUp" data-ani-delay="0.6s" style="animation-delay: 0.6s; visibility: visible; animation-name: fadeInUp;">Beenify Me</a>
                    </div>

                </div>
            </div>
        </section>



        <section class=" space mt-1 fix bg-white">
            <div class="container">
                <div class="row justify-content-center" data-cues="fadeIn" data-disabled="true">
                    <div class="col-xl-5 col-lg-5 col-md-12">
                        <div class="title-area">
                            <h5 class="subtitle wow fadeInUp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">Advantage Beenify</h5>
                            <h2 class="title mb-20 wow fadeInUp" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">Business Owners Need a Clear Picture</h2>
                        </div>
                        <div class="work-process-image">
                            <img decoding="async" src="@/assets/Theme/CSS/img/clearpicture.png" alt="images">
                        </div>
                    </div>
                    <div class="col-xl-1 col-lg-0 hide-dn"></div>
                    <div class="col-lg-6 col-md-12">
                        <div class="work-process-right">
                            <div class="right-card wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                <div class="number">01.</div>
                                <h3>Drop Shipping Network</h3>
                                <p>Access a vast network of pet supplies for seamless delivery, expanding your product selection without inventory burden.</p>
                            </div>
                            <div class="right-card wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                <div class="number">02.</div>
                                <h3>Focused Animal Care Plans</h3>
                                <p>Club products and create packages focused on animal wellness.</p>
                            </div>
                            <div class="right-card wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                <div class="number">03.</div>
                                <h3>On-Demand Vet Consultations</h3>
                                <p>A network of veterinarians to offer remote consultations, increasing customer convenience and revenue streams</p>
                            </div>
                            <div class="right-card wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                <div class="number">04.</div>
                                <h3>Medical/Pharmaceutical Subscriptions</h3>
                                <p>Simplify recurring medication needs for pets with convenient subscription options.</p>
                            </div>
                        </div>
                        <div class="about-btn mt-3">
                            <a href="Contact.html" class="default-btn">Setup a Call</a>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-10 col-md-12 text-center mt-5">
                        <p class="greentxt">Allow customers to easily set up recurring orders for their favorite products, fostering customer loyalty and predictable revenue streams.</p>
                    </div>

                </div>
            </div>
        </section>


        <section class="about-area space fix position-reltive bg-light">
            <div class="about-wrap style1">

                <div class="container-lg">
                    <div class="about-wrapper style3">

                        <div class="about-content wow fadeInRight" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInRight;">
                            <div class="title-area">
                                <h5 class="subtitle"> Featured</h5>
                                <h2 class="title text-start">Beyond Feed </h2>
                                <p class="text">Beenify is the ultimate solution for Feed &amp; Pet supply stores looking to establish an online presence and streamline their operations. </p>
                                <ul class="listitems">
                                    <li><i class="fa-solid fa-check"></i> Easy Products Setup </li>
                                    <li><i class="fa-solid fa-check"></i> Manage Shipping Rules</li>
                                    <li><i class="fa-solid fa-check"></i> Sell Your Products in Local Market as well as on Beenee Marketplace</li>
                                    <li><i class="fa-solid fa-check"></i> Out of Stock Alerts</li>
                                    <li><i class="fa-solid fa-check"></i> Multiple Shipping Method</li>
                                    <li><i class="fa-solid fa-check"></i> Pickup from Store</li>
                                </ul>
                                <a href="#contact-form" class="gt-btn btn-yellow">Get Your FREE Website</a>
                            </div>
                        </div>

                        <div class="about-thumb-box radiusimg wow fadeInLeft" data-wow-delay=".4s" style="visibility: visible; animation-delay: 0.4s; animation-name: fadeInLeft;">
                            <img src="@/assets/Theme/CSS/img/beyondfeed.png" alt="beyondfeed">
                        </div>

                    </div>
                </div>
            </div>
        </section>


        <section class="project-area">
            <div class="project-wrap style1 space fix arrow-wrap">
                <div class="container">
                    <div class="title-wrap mb-50">
                        <div class="title-area">
                            <h5 class="subtitle"> Industries</h5>

                            <h2 class="title text-start text-white wow fadeInUp" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;">Industries Already Beenified</h2>
                        </div>
                        <div class="arrow-btn text-end wow fadeInUp" data-wow-delay=".9s" style="visibility: visible; animation-delay: 0.9s; animation-name: fadeInUp;">
                            <button data-slider-prev="#projectSlider1" class="slider-arrow style2"><img src="@/assets/Theme/CSS/img/icon/arrowLeft.png" alt="img"></button>
                            <button data-slider-next="#projectSlider1" class="slider-arrow style2 slider-next"><img src="@/assets/Theme/CSS/img/icon/arrowRight.png" alt="img"></button>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="slider-area project-slider1 wow fadeInUp">
                        <div class="gt-slider" id="projectSlider1">

                            <swiper :slides-per-view="4.2"
                                    :space-between="30"
                                    @swiper="onSwiper"
                                    :pagination="{ clickable: true }"
                                    @slideChange="onSlideChange"
                                    :autoplay="{ delay: 3000, disableOnInteraction: false }">
                                <swiper-slide>
                                    <div class="project-card style1 img-shine">
                                        <div class="project-img">
                                            <img src="@/assets/Theme/CSS/img/prod-img1.png" alt="project image">
                                        </div>
                                        <div class="fancy-box style2">
                                            <p>Knick Knack Store </p>

                                            <a href="#" class="linkarrow">
                                                Learn More <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="project-card style1 img-shine">
                                        <div class="project-img ">
                                            <img src="@/assets/Theme/CSS/img/prod-img2.png" alt="project image">
                                        </div>
                                        <div class="fancy-box style2">
                                            <p>Pet Store</p>
                                            <a href="#" class="linkarrow">
                                                Learn More <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="project-card style1 img-shine">
                                        <div class="project-img">
                                            <img src="@/assets/Theme/CSS/img/prod-img3.png" alt="project image">
                                        </div>
                                        <div class="fancy-box style2">
                                            <p>Americana Store</p>
                                            <a href="#" class="linkarrow">
                                                Learn More <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="project-card style1 img-shine">
                                        <div class="project-img">
                                            <img src="@/assets/Theme/CSS/img/prod-img1.png" alt="project image">
                                        </div>
                                        <div class="fancy-box style2">
                                            <p>Knick Knack Store </p>

                                            <a href="#" class="linkarrow">
                                                Learn More <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </swiper-slide>
                                <swiper-slide>
                                    <div class="project-card style1 img-shine">
                                        <div class="project-img ">
                                            <img src="@/assets/Theme/CSS/img/prod-img2.png" alt="project image">
                                        </div>
                                        <div class="fancy-box style2">
                                            <p>Pet Store</p>
                                            <a href="#" class="linkarrow">
                                                Learn More <i class="fa-solid fa-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                </swiper-slide>

                            </swiper>





                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="testimonial-area space fix arrow-wrap">
            <div class="container">
                <div class="testimonial-wrap style1 ">
                    <div class="title-wrap mb-50">
                        <div class="title-area text-start">
                            <h5 class="subtitle"> Customer Feedback</h5>
                            <h2 class="title text-start wow fadeInUp" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;">What Our Clients Say </h2>
                        </div>

                    </div>

                    <div class="slider-area testimonial-slider1">
                        <div class="swiper gt-slider" id="testimonialSlider1">

                            <swiper :slides-per-view="3"
                                    :space-between="50"
                                    @swiper="onSwiper"
                                    @slideChange="onSlideChange"
                                    :autoplay="{ delay: 3000, disableOnInteraction: false }"
                                    :pagination="{ clickable: true }">
                                <swiper-slide>
                                    <div class="testimonial-card style1">
                                        <div class="profile-box">
                                            <div class="testi-content">
                                                <ul class="star-wrap">
                                                    <li><i class="fa-solid fa-star"></i></li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                    <li><i class="fa-solid fa-star"></i></li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p class="text">
                                            "Beenify transformed my small pet shop into an online powerhouse. The free custom website was ready in just one day, and their CRM tools make managing customer relationships a breeze. I can't believe how easy it is to keep track of inventory and orders now-this platform truly does it all!"
                                        </p>
                                        <div class="authorinfo">
                                            <h3 class="title">Noah Gonzalez, Pet Shop Owner</h3>
                                            <div class="date">August 29, 2024</div>

                                            <div class="quote"><img src="@/assets/Theme/CSS/img/quote.png" alt="quote"> </div>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="testimonial-card style1">
                                        <div class="profile-box">
                                            <div class="testi-content">
                                                <ul class="star-wrap">
                                                    <li><i class="fa-solid fa-star"></i></li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                    <li><i class="fa-solid fa-star"></i></li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p class="text">
                                            "As a feed store owner, Beenify has been a seamless inventory management system that keeps everything running smoothly, and the automatic sales tax calculation has taken the stress out of accounting. My sales have never been better!"
                                        </p>
                                        <div class="authorinfo">
                                            <h3 class="title">Brandon Park, Feed Store Owner</h3>
                                            <div class="date">August 18, 2024</div>
                                            <div class="quote"><img src="@/assets/Theme/CSS/img/quote.png" alt="quote"> </div>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="testimonial-card style1">
                                        <div class="profile-box">
                                            <div class="testi-content">
                                                <ul class="star-wrap">
                                                    <li><i class="fa-solid fa-star"></i></li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                    <li><i class="fa-solid fa-star"></i></li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p class="text">
                                            "Beenify's real-time order tracking and optimized shipping routes have drastically improved my store's delivery efficiency. My customers loved the transparency, and I've noticed significant savings on shipping cost, thanks to their local network and independent delivery drivers."
                                        </p>
                                        <div class="authorinfo">
                                            <h3 class="title">Nicholas Galvin, Knick-Knack <br>Store Owner</h3>
                                            <div class="date">August 12, 2024</div>
                                            <div class="quote"><img src="@/assets/Theme/CSS/img/quote.png" alt="quote"> </div>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="testimonial-card style1">
                                        <div class="profile-box">
                                            <div class="testi-content">
                                                <ul class="star-wrap">
                                                    <li><i class="fa-solid fa-star"></i></li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                    <li><i class="fa-solid fa-star"></i></li>
                                                    <li><i class="fa-solid fa-star"></i> </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p class="text">
                                            "Since joining the Beenify network, my store's online presence has grown tremendously. I especially appreciate the uniform brand experience they provide with their white-labeled shipments-it's exactly what I needed to expand my business."
                                        </p>
                                        <div id="contact-form" class="authorinfo">
                                            <h3 class="title">James Glassman, Local Retailer</h3>
                                            <div class="date">August 5, 2024</div>
                                            <div class="quote"><img src="@/assets/Theme/CSS/img/quote.png" alt="quote"> </div>
                                        </div>
                                    </div>
                                </swiper-slide>
                            </swiper>

                        </div>
                        <button data-slider-prev="#testimonialSlider1" class="slider-arrow style2 d-lg-block d-none"><img src="assets/img/icon/arrowLeft.png" alt="img"></button>
                        <button data-slider-next="#testimonialSlider1" class="slider-arrow style2 slider-next d-lg-block d-none"><img src="assets/img/icon/arrowRight.png" alt="img"></button>
                    </div>
                </div>
            </div>
        </section>


        <section class="contact-wrap-area ptb-120 pt-0 space fix">
            <div class="container">
                <div class="row justify-content-center" data-cues="fadeIn">
                    <div class="col-lg-6 col-md-12" data-cue="fadeIn">
                        <div class="contact-wrap-content">
                            <div class="title-area text-start">
                                <h2 class="title text-start wow fadeInUp" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;">Contact us </h2>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-lg-12 col-sm-12">
                                    <div class="info-card ">
                                        <h3>Head office</h3>
                                        <ul class="list">
                                            <li><i class="fa-solid fa-location-dot"></i> 11084 Sheldon St. Sun Valley, CA 91352, USA</li>
                                            <li><i class="fa-regular fa-envelope"></i> info@beenify.com</li>
                                            <li><h4><a href="tel:1556566-5544">(818) 767-3060</a></h4></li>
                                        </ul>
                                    </div>
                                    <div class="radisuimg mt-3">

                                        <img src="@/assets/Theme/CSS/img/contactimg.png" alt="contactimg">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact-wrap-image"></div>
                    </div>
                    <div class="col-lg-6 col-md-12" data-cue="fadeIn" data-show="true">
                        <div class="contact-wrap-form">
                            <h3> Get a Free website today</h3>

                            <div class="wpcf7 js" id="wpcf7-f197-p30-o1">

                                <!--<form action="http://202.164.57.203/DotNet/beenee/beenify/html/Contact.html" method="post" class="wpcf7-form init">
                                    <div class="contact-form">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-6 col-md-12">
                                                <div class="form-group">
                                                    <p>
                                                        <label>Name</label><span class="wpcf7-form-control-wrap" data-name="name"><input size="40" maxlength="400" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false" value="" type="text" name="name"></span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-12">
                                                <div class="form-group">
                                                    <p>
                                                        <label>Phone</label><span class="wpcf7-form-control-wrap" data-name="numbe"><input class="wpcf7-form-control wpcf7-number wpcf7-validates-as-required wpcf7-validates-as-number form-control" aria-required="true" aria-invalid="false" value="" type="number" name="numbe"></span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-12">
                                                <div class="form-group">
                                                    <p>
                                                        <label>Email</label><span class="wpcf7-form-control-wrap" data-name="email"><input size="40" maxlength="400" class="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email form-control" aria-required="true" aria-invalid="false" value="" type="email" name="email"></span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-12">
                                                <div class="form-group">
                                                    <p>
                                                        <label>Business Name</label><span class="wpcf7-form-control-wrap" data-name="bname"><input size="40" maxlength="400" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false" value="" type="text" name="bname"></span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <p>
                                                        <label>Your Message</label><span class="wpcf7-form-control-wrap" data-name="message"><textarea cols="40" rows="10" maxlength="2000" class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required form-control" aria-required="true" aria-invalid="false" name="message"></textarea></span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <p>
                                                    <button type="submit" class="default-btn">Submit</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </form>-->
                                <Form autocomplete="off" @submit="onSubmit()" :validation-schema="schema" v-slot="{ errors }" class="row gx-3">
                                    <!--<div class="row">-->
                                    <div class="form-group col-md-6 mb-3">
                                        <label>Name</label>
                                        <Field type="text" name="name"
                                               v-model="contactusData.name"
                                               maxlength="50"
                                               placeholder="Name*"
                                               :class="{ 'form-control wpcf7-form-control' : true ,'is-invalid': errors.name }" />
                                        <div class="invalid-feedback">{{errors.name}}</div>
                                    </div>

                                    <div class="form-group col-md-6 mb-3">
                                        <label>Email</label>
                                        <Field type="text" name="email" v-model="contactusData.email"
                                               :class="{'form-control wpcf7-form-control' : true , 'is-invalid': errors.email }"
                                               maxlength="50" placeholder="Email*" />
                                        <div class="invalid-feedback">{{errors.email}}</div>
                                    </div>
                                    <div class="form-group col-md-6 mb-3">
                                        <label>Phone</label>
                                        <Field v-slot="{ Field }"
                                               v-model="contactusData.phone"
                                               name="phone"
                                               value="value">

                                            <input-mask name="phone"
                                                        v-model="contactusData.phone"
                                                        mask="(999) 999-9999"
                                                        :class="{ 'is-invalid': errors.phone }"
                                                        placeholder="Phone*"
                                                        class="form-control wpcf7-form-control"
                                                        type="text"></input-mask>
                                            <div class="invalid-feedback">{{errors.phone}}</div>
                                        </Field>
                                    </div>
                                    <!--<div class="form-group col-md-6 mb-3">
                                        <Field type="text" name="companyname" v-model="contactusData.companyname"
                                               :class="{'form-control' : true , 'is-invalid': errors.companyname }"
                                               maxlength="50" placeholder="Enter Company Name*" />
                                        <div class="invalid-feedback">{{errors.companyname}}</div>
                                    </div>-->
                                    <div class="form-group col-md-6 mb-3">
                                        <label>Business Name</label>
                                        <Field type="text" v-model="contactusData.subject" name="subject"
                                               :class="{'form-control wpcf7-form-control' : true , 'is-invalid': errors.subject }"
                                               placeholder="Business Name*" />
                                        <div class="invalid-feedback">{{errors.subject}}</div>
                                    </div>
                                    <div class="form-group col-md-12 mb-3">
                                        <label>Message</label>
                                        <Field name="message" v-model="contactusData.message">
                                            <textarea v-model="contactusData.message"
                                                      :class="{'form-control wpcf7-form-control' : true , 'is-invalid': errors.message }"
                                                      placeholder="Message*" />
                                        </Field>
                                        <div class="invalid-feedback">{{errors.message}}</div>
                                    </div>
                                    <div class="col-md-8" :class="!isCapchaValid?'border border-danger':''">
                                        <div class="form-group mb-3">
                                            <div id="recaptcha-BefyHomeView" class="g-recaptcha" :data-sitekey="siteKey">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-area submit-area w-100">
                                        <button type="submit" class="btn btn-primary">Send Message</button>
                                    </div>
                                    <!--</div>-->
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>




    </main>









    <Loading v-model:active="isLoading">
    </Loading>

</template>

<script>

    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Form, Field } from 'vee-validate';
    import { Pagination, Navigation, Scrollbar, Autoplay } from 'swiper';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';
    import 'swiper/css/virtual';
    import service from '@/service/FrontendService.js'
    import * as Yup from 'yup';
    import InputMask from "@/views/shared/inputmask/inputmasktemplate.vue"
    import ECommerce from '@/service/ECommerce.js';
    import 'swiper/css';


    /*    import FilterUtility10 from '@/assets/Theme/CSS/js/vendor/jquery-3.7.1.min.js';*/

    import FilterUtility1 from '@/assets/Theme/CSS/js/swiper-bundle.min.js';

    //import FilterUtility2 from '@/assets/Theme/CSS/js/bootstrap.min.js';

    //import FilterUtility3 from '@/assets/Theme/CSS/js/wow.min.js';

    //import FilterUtility4 from '@/assets/Theme/CSS/js/jquery.waypoints.min.js';

    //import FilterUtility5 from '@/assets/Theme/CSS/js/tilt.min.js';

    //import FilterUtility6 from '@/assets/Theme/CSS/js/jquery.magnific-popup.min.js';

    //import FilterUtility7 from '@/assets/Theme/CSS/js/jquery.counterup.min.js';

    //import FilterUtility8 from '@/assets/Theme/CSS/js/gsap.min.js';



    //import FilterUtility9 from '@/assets/Theme/CSS/js/main.js'


    export default {
        name: 'HomeView',
        components: {
            Form, Field, InputMask,
            Swiper,
            SwiperSlide, FilterUtility1
            //, FilterUtility10
            /*Header*/
        },
        props: ['homes', 'searchValue'],
        data() {
            const companyId = localStorage.getItem("SupcompanyId")
            const schema = Yup.object().shape({
                name: Yup.string().required('First name is required'),
                /*  lastname: Yup.string().required('Last name is required'),*/
                phone: Yup.string().required('Phone is required'),
                /* companyname: Yup.string().required('Company Name is required'),*/
                subject: Yup.string().required('Business Name is required'),
                message: Yup.string().required('Message is required'),
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            const schemaemail = Yup.object().shape({
                //email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            return {
                modules: [Pagination, Navigation, Scrollbar, Autoplay],
                schema,
                schemaemail,
                firstname: '',
                lastname: '',
                contactusData: {
                    id: null,
                    name: '',
                    companyname: '',
                    email: null,
                    phone: '',
                    subject: '',
                    message: '',
                    type: '',
                    companyId: localStorage.getItem("SupcompanyId")
                },

                ProductCatgoryList: null,
                //TopProductCatgoryList: null,
                ProductList: '',
                RecommendedProductList: [],
                SaverTodayProductList: [],
                emailvalue: null,
                isLoading: true,
                isMarketPlace: true,
                IsFeateured: true,
                IsFeateureds: false,
                companyId,

                id: null,
                search: '',
                Headerpage: true,
                searchproduct: '00Test',
                CompanyDynamicContent: [],
                bannervideo: [],
                description: [],
                siteKey: '6LfPmGQnAAAAAKF_W3hIf1iiQ33tbLRw4qmKYdBO',
                isCapchaValid: true,
            }
        },
        created() {

            setTimeout(() => {
                grecaptcha.render('recaptcha-BefyHomeView');
            }, 300);
            if (this.searchValue != null || this.searchValue != undefined) {
                this.searchproduct = this.searchValue;;
                this.$emit("Homesss");
            }
            this.isLoading = true;
            this.GetProductList();
            this.GetProductCatgoryList();
            this.GetSaverTodayProductList();
            this.GetRecommendedProductList();
            this.GetDynamicContent();
            (function () {
                const second = 1000,
                    minute = second * 60,
                    hour = minute * 60,
                    day = hour * 24;

                //I'm adding this section so I don't have to keep updating this pen every year :-)
                //remove this if you don't need it
                let today = new Date(),
                    dd = String(today.getDate()).padStart(2, "0"),
                    mm = String(today.getMonth() + 1).padStart(2, "0"),
                    yyyy = today.getFullYear(),
                    nextYear = yyyy + 1,
                    dayMonth = "09/30/",
                    birthday = dayMonth + yyyy;

                //today = mm + "/" + dd + "/" + yyyy;
                //if (today > birthday) {
                //    birthday = dayMonth + nextYear;
                //}
                //end

                const countDown = new Date(birthday).getTime(),
                    x = setInterval(function () {

                        const now = new Date().getTime(),
                            distance = countDown - now;
                    }, 0)
            }())

        },

        updated() {
            window.scrollTo(0, 0);
        },
        mounted() {
            //
            this.$route.params
        },




        methods: {
            onSwiper(swiper) {
                console.log(swiper);
            },
            onSlideChange() {
                console.log('Slide changed');
            },
            GetDynamicContent() {
                this.isLoading = true;
                ECommerce.GetDynamicContentForComapny(localStorage.getItem("companyId")).then(res => {
                    this.CompanyDynamicContent = res.data;
                    this.bannervideo = this.CompanyDynamicContent.bannerVideoLink.split(',');
                    if (this.CompanyDynamicContent.description != null) {
                        this.description.push(this.CompanyDynamicContent.description.split('</p>,'));
                        this.isLoading = false;
                    }
                    else {
                        this.description = null;
                        this.isLoading = false;
                    }
                    const favicon = document.getElementById("favicon");
                    favicon.href = this.CompanyDynamicContent.favIconLink;
                    this.$parent.$parent.FooterLogoDescription = res.data.footerContent;
                    this.isLoading = false;
                })
            },
            headerfalse() {
                //
                this.hom = false;;
            },
            Search(value) {


                this.search = value
            },
            GetProductList() {
                this.isLoading = true;
                this.IsFeateured = false;
                this.companyId = '00Test'
                this.searchproduct = (this.searchproduct == null) ? '00Test' : this.searchproduct;
                ECommerce.GetProductListingFront_Home(this.isMarketPlace, this.IsFeateureds, this.companyId, this.searchproduct).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        console.log('data', response.data);
                        this.ProductList = null;
                        this.ProductList = response.data
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            GetRecommendedProductList() {
                this.isLoading = true;
                this.IsFeateured = false;
                this.companyId = '00Test'
                ECommerce.GetRecommededProductListingFrontHome(this.isMarketPlace, this.IsFeateured, this.companyId, this.searchValue).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        ///console.log('data', response.data);
                        this.RecommendedProductList = response.data;
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            GetSaverTodayProductList() {
                this.isLoading = true;
                this.IsFeateured = false;
                this.companyId = '00Test'
                ECommerce.GetSaverTodayProductListingHome(this.isMarketPlace, this.IsFeateured, this.companyId).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        //console.log('data', response.data);
                        this.SaverTodayProductList = response.data;
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            GetProductCatgoryList() {
                this.isLoading = true;
                this.companyId = '00Test'
                this.IsFeateureds = true;
                ECommerce.GetProductCategoryListFront(this.isMarketPlace, this.IsFeateureds, this.companyId).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        //console.log('ProductCatgoryListdata', response.data);
                        this.ProductCatgoryList = response.data
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            productdetails(Id) {
                this.$router.push({ path: `/Products/${Id}` })
            },

            redirectproductlisting() {
                //redirects to product page(all categories)
                this.$router.push({ path: `/ProductList` })
                // this.$router.push({ path: `/Products` })
            },
            onSubmit() {
                if (grecaptcha.getResponse().length === 0 || grecaptcha.getResponse() == "") {
                    this.isCapchaValid = false;
                    return
                }
                else {
                    this.isCapchaValid = true;
                }
                this.isLoading = true;
                this.contactusData.type = 'beenify Contact';
                service.savecontactus(this.contactusData).then(res => {
                    this.isLoading = false;
                    if (res.data == 1 || res.data == '1') {
                        this.success('Your request has been submitted successfully. Our team will contact you soon.');
                        //this.$router.push({ name: 'thankyou' });
                        this.$router.push({
                            name: "thankyou",
                            state: {
                                routeMessage: {
                                    IsSuccess: true,
                                    displayMessage: 'Your request has been submitted successfully. Our team will contact you soon.'
                                }
                            }
                        });
                    }
                    else {
                        this.error('Something went wrong')
                    }
                }).catch(res => {
                    this.error("Something went wrong. Please contact site administrator.");
                    this.isLoading = false;
                })
            },

            //onSubmit() {
            //    debugger;
            //    this.isLoading = true;
            //    this.contactusData.name = this.firstname + ' ' + this.lastname;
            //    this.contactusData.type = 'Request Demo';
            //    service.savecontactus(this.contactusData).then(res => {
            //        this.isLoading = false;
            //        if (res.data == 1) {
            //            this.success('Your request has been submitted successfully. Our team will contact you soon.');
            //            this.$router.push({
            //                name: "thankyou",
            //                state: {
            //                    routeMessage: {
            //                        IsSuccess: true,
            //                        displayMessage: 'Your request has been submitted successfully. Our team will contact you soon.'
            //                    }
            //                }
            //            });
            //        }
            //        else {
            //            this.error('Something went wrong')
            //        }
            //    }).catch(res => {
            //        this.error("Something went wrong. Please contact site administrator.");
            //        this.isLoading = false;
            //    })
            //},
            ProductDetailpage(Id) {
                this.$router.push({ path: `/ProductDetail/${Id}` })
            },
            Path(Id) {
                this.isLoading = true;
                if (localStorage.getItem('userlogin') == "false") {
                    this.$router.push({ path: `/Login` })
                }

                else {
                    //let subscriptionInfo = localStorage.getItem("SubscriptionDetails");
                    //var subscriptiondata = JSON.parse(subscriptionInfo);
                    //if (subscriptiondata != null) {
                    //    if (subscriptiondata.subscriptiontype != null || subscriptiondata.subscriptiontype != 'undefined') {
                    //        this.error("You cannot add more than one product in subscription!");
                    //        this.isLoading = false;
                    //        return;
                    //    }
                    //}
                    localStorage.setItem('cartaction', 'cartcalc');
                    this.$router.push({ path: `/Cart/${Id}` })
                }
                this.isLoading = false;

            },
        }
    }
</script>



