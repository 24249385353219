<template>
    <footer class="footer-area">
        <div class="widget-area style1  pt-100 pb-80">
            <div class="container">
                <div class="footer-layout style1">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-12">
                            <div class="widget footer-widget wow fadeInUp" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;">
                                <div class="gt-widget-about">
                                    <div class="about-logo">

                                        <router-link to="/">
                                            <img src="../../assets/Theme/CSS/img/logo-white.png" alt="Realar">
                                        </router-link>
                                    </div>
                                    <!-- <p class="about-text"> Subscribe for the latest updates!  </p> -->
                                    <!-- <form class="mailchimp newsletter-form" method="post" novalidate="true">
                                        <input type="email" class="input-newsletter memail" placeholder="Enter your email address" name="EMAIL" required="">
                                        <button type="submit"><i class="fa-solid fa-paper-plane"></i> Subscribe</button>
                                    </form> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-7 col-12">
                            <div class="row">
                                <div class="col-xl-6 col-md-6 col-12">
                                    <div class="widget widget_nav_menu footer-widget wow fadeInUp" data-wow-delay="1s" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">
                                        <h3 class="widget_title">Quick Links</h3>
                                        <div class="menu-all-pages-container">
                                            <ul class="menu">
                                                <li><router-link href="javascript:;" to="/Home">Home</router-link></li>
                                                <li><router-link href="javascript:;" to="/About-Us">About us</router-link></li>
                                                <li><router-link href="javascript:;" to="/contactus">Contact us</router-link></li>
                                                <li><router-link href="javascript:;" to="/Privacy-Policy">Privacy policy</router-link></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-md-6 col-12">
                                    <div class="widget widget_nav_menu footer-widget wow fadeInUp" data-wow-delay="1s" style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;">
                                        <h3 class="widget_title">Solutions</h3>
                                        <div class="menu-all-pages-container">
                                            <ul class="menu">
                                                <li><router-link href="javascript:;" to="/Industries">Industries</router-link></li>
                                                <li><router-link href="javascript:;" to="/HelpCenter">Help Center</router-link></li>
                                                <!--<li><a href="javascript:void(0)">Industries</a></li>
                                                <li><a href="javascript:void(0)">Help Center</a></li>-->
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-3 col-md-5 col-12">
                            <div class="widget widget_nav_menu footer-widget wow fadeInUp" data-wow-delay="1.6s" style="visibility: visible; animation-delay: 1.6s; animation-name: fadeInUp;">
                                <h3 class="widget_title">Contact Us</h3>
                                <div class="checklist">
                                    <ul class="ps-0">
                                        <li class="text-grey">11084 Sheldon St, Sun Valley, CA 91352, USA</li>
                                    </ul>
                                    <ul class="ps-0">
                                        <li class="text-grey"><i class="fa-thin fa-envelope"></i></li>
                                        <li class="text-grey">info@beenify.com</li>
                                    </ul>
                                    <ul class="ps-0">
                                        <li class="text-grey phonenumb">+1 (818) 767-3060</li>
                                    </ul>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-wrap ">
            <div class="container">
                <div class="copyright-layout">
                    <div class="layout-text wow fadeInUp" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
                        <p class="copyright">
                            <i class="fal fa-copyright"></i> 2024 Beenify. All rights reserved.
                        </p>
                    </div>
                    <div class="layout-link wow fadeInUp" data-wow-delay=".6s" style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp;">
                        <div class="link-wrapper">
                            <div class="gt-social style2">
                                <a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a>
                                <a href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
                                <a href="javascript:void(0)"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div class="scroll-top show">
        <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" style="transition: stroke-dashoffset 10ms linear; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 271.126;">
            </path>
        </svg>
    </div>
</template>
<script>
    import UserFrontendService from '@/service/FrontendService.js'
    import { Form, Field } from 'vee-validate';
    import * as Yup from 'yup';
    export default {
        name: 'FooterView',
        components: {
            Form, Field
        },
        props: ['footerNavLinks1', 'footerNavLinks2', 'footerNavLinks3', 'FooterworkusNavLinks', 'FooterLogoDescription'],
        data() {
            const searchModel = {
                searchItem: null,
                status: -1,
                sortColumn: 'CreatedOn',
                sortDir: 'desc',
                pageIndex: 0,
                pageSize: 10,
                userId: localStorage.getItem("userId"),
                companyId: localStorage.getItem("SupcompanyId")
            }
            const schemaemail = Yup.object().shape({
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            return {
                socialmedialinkList: [],
                searchModel,
                TopProductCatgoryList: null,
                isMarketPlace: true,
                IsFeateured: true,
                schemaemail,
                emailvalue: null,
                //Email:null,
                //Phone:null,
                //footerContent:null,
                companyphone: localStorage.getItem('companyphone'),
                companyemail: localStorage.getItem('companyemail'),
                companyfootercontent: localStorage.getItem('companycontent'),
                companySecondaryLogo: '',
            }
        },
        created() {
            this.getListOfSocialLinks();
            this.getCompanyLogo();
            if (this.companyphone == "(___) ___-____") {
                this.companyphone = null;
            }
            //this.GetTopProductCatgoryList();

        },
        mounted() {

            this.GetTopProductCatgoryList();
        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companySecondaryLogo'); //localStorage.getItem('companySecondaryLogo');
                this.companySecondaryLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../assets/images/logo.png') : logoUrl;
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
            getListOfSocialLinks() {
                this.isLoding = true;
                UserFrontendService.GetSocialLinkList(this.searchModel.searchItem, this.searchModel.sortColumn, this.searchModel.sortDir, this.searchModel.pageIndex, this.searchModel.pageSize, '00000000-0000-0000-0000-000000000000', '00000000-0000-0000-0000-000000000000', 1).then(response => {
                    console.log('response', response);
                    this.socialmedialinkList = response.data.data;
                })
                setTimeout(() => { this.isLoding = false; }, 1000);

            },
            GetTopProductCatgoryList() {
                //debugger
                this.isLoading = true;
                this.isMarketPlace = true;
                this.companyId = '00Test';
                this.IsFeateured = false;
                UserFrontendService.GetTopFiveCategory(this.isMarketPlace, this.companyId, this.IsFeateured).then((response) => {
                   // debugger;
                    if (this.$filters.IsNull(response.data) != null) {
                        console.log('data', response.data);
                        this.TopProductCatgoryList = response.data
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            Productdetails(Id) {
               // debugger;
                //this.scrollToTop();
                this.$router.push({ path: `/Products/${Id}` });

            },
            redirectotrackorder() {
                this.scrollToTop();
                this.$router.push({ path: `/OrderTracking` })
            },
            redirectoreturnpolicy() {
                this.scrollToTop();
                this.$router.push({ path: `/ReturnPolicy` })
            },
            redirectodeliveryoption() {
                this.scrollToTop();
                this.$router.push({ path: `/DeliveryOptions` })
            },
            redirectotaxexemption() {
                this.scrollToTop();
                this.$router.push({ path: `/TaxExemptions` })
            },
            redirectocontact() {
                this.scrollToTop();
                this.$router.push({ path: `/contactus` })
            },
            redirectoFaq() {
                this.scrollToTop();
                this.$router.push({ path: `/FAQ` })
            },
            async emailsubmit() {
               // debugger;
                this.isLoading = true;
                var manageSubscribersModel = {
                    email: this.emailvalue
                }
                await UserFrontendService.AddSubscriber(manageSubscribersModel).then(Response => {
                   // debugger;
                    if (Response != null && Response.data.statusCode == 200 || Response.data.statusCode == 201) {
                        this.isLoading = false;
                        if (Response.data.statusCode == 200) {
                            this.$router.push({
                                name: `thankyou`,
                                state: {
                                    routeMessage: {
                                        isSuccess: true,
                                        displayMessage: 'You have successfully joined our subscriber list'
                                    }
                                }
                            }
                            )
                        }
                        else {
                            this.$router.push({
                                name: `thankyou`,
                                state: {
                                    routeMessage: {
                                        Email: this.emailvalue,
                                        isSuccess: false,
                                    }
                                }
                            });
                        }
                    }
                    else {
                        this.isLoading = false;
                        this.error('Bad req')
                    }


                }).catch(res => {
                    this.error("Something went wrong. Please contact site administrator.");
                    this.isLoading = false;
                })
                this.$refs.refData.resetForm();

            }


        }
    }

</script>
